.App {
  color: #343a40;
}

.App-logo {
  max-height: 60px;
  pointer-events: none;
}

.App-header {
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;
  padding: 50px;
  font-size: calc(10px + 2vmin);
  background-color: white;
}

.tagline {
  padding: 15px 0px;
  color: transparent;
  background-image: linear-gradient(135deg, #e64980, #fab005);
  -webkit-background-clip: text;
  text-align: center;
  font-size: min(max(2.5vw, 20px), 34px) !important;
}

.description {
  background-color: #343a40;
  color: white;
  padding: 40px;
  display: flex;
  place-content: center;
}

.description > * {
  max-width: 70ch;
}

.features::before {
  width: 100%;
  height: 60px;
  background-color: #343a40;
  position: absolute;
  top: 0px;
  left: 0px;
  content: "";
  display: block;
  z-index: -1;
}

.features {
  padding-top: 10px;
  position: relative;
  width: 100%;
  display: flex;
  place-content: center;
  flex-wrap: wrap;
  padding: 20px;
}

.features > div {
  flex: 1;
  min-width: 200px;
  max-width: 250px;
}

.feature {
  border-radius: 4px;
  padding: 16px;
}

.feature > div {
  align-items: center;
}

.feature svg {
  height: 2em;
  width: 2em;
}

.text {
  margin: 24px;
}

.button {
  margin: 16px !important;
}

.App-link {
  color: #1c7ed6;
}

.survey {
  text-align: center;
  padding: 50px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
